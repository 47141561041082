<template>
  <v-layout align-center justify-center>
    <v-flex xs12 sm8 md3>
      <v-card class="elevation-12">
        <v-toolbar color="primary white--text">
          <v-toolbar-title>Recuperar senha</v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                label="Nova senha"
                type="password"
                required
                v-model="form.password"
                class="required"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                label="Confirmar nova senha"
                type="password"
                required
                v-model="form.confirmPassword"
                class="required"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <div class="pa-4">
          <v-btn
            block
            color="black white--text mb-3"
            class="pa-6"
            @click="saveNewPassword()"
            :loading="loading"
            >Salvar</v-btn
          >
        </div>
      </v-card>
    </v-flex>

    <v-dialog v-model="successRecoveryPasswordDialog" max-width="400">
      <v-card>
        <v-card-title> Sucesso! </v-card-title>
        <v-card-text>
          Senha redefinida com sucesso, faça seu login novamente
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text to="/login">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import errorHandler from "@/helpers/error_handler";
import { mapMutations } from "vuex";

export default {
  data: () => ({
    successRecoveryPasswordDialog: false,
    loading: false,
    form: {},
  }),
  methods: {
    ...mapMutations("user", ["clearUser"]),
    async saveNewPassword() {
      try {
        this.loading = true;

        await this.$axios.post("/usuarios/recuperarsenha/confirmar", {
          ...this.form,
          token: this.$route.query.token,
        });

        this.clearUser();

        this.successRecoveryPasswordDialog = true;
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      if (Array.isArray(errorHandled))
        errorHandled.map((e) => this.$toast.error(e));
      else this.$toast.error(errorHandled);
    },
  },
};
</script>

<style scoped>
.layout {
  height: 100vh;
}
</style>

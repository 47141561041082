<template>
  <div class="wrapper">
    <div class="background"></div>
    <v-card min-width="350" class="elevation-12">
      <v-card-text>
        <v-row>
          <v-col class="d-flex align-center flex-column">
            <h2>φ Dialeticos - Rede social filosófica</h2>
            <p class="mt-2">Entre ou cadastre-se</p>
          </v-col>
        </v-row>

        <v-row no-gutters v-if="errorMsg">
          <v-col>
            <v-alert dense outlined type="error">
              {{ errorMsg }}
            </v-alert>
          </v-col>
        </v-row>

        <v-form>
          <v-text-field
            prepend-icon="mdi-account"
            name="email"
            label="E-Mail"
            type="text"
            v-model="form.email"
            :disabled="loading"
            v-on:keyup.enter="signin()"
          ></v-text-field>
          <v-text-field
            id="password"
            prepend-icon="mdi-lock"
            name="password"
            label="Senha"
            type="password"
            v-model="form.password"
            :disabled="loading"
            v-on:keyup.enter="signin()"
          ></v-text-field>
        </v-form>
      </v-card-text>

      <div class="pa-4">
        <v-btn
          block
          color="black white--text mb-3"
          class="pa-6"
          @click="signin()"
          :loading="loading"
          >Entrar</v-btn
        >

        <v-btn
          block
          color="info white--text mb-3"
          class="pa-6"
          @click.stop="registerDialog = true"
          :loading="loading"
          >Cadastrar</v-btn
        >
      </div>

      <div class="text-right pa-2">
        <v-btn
          text
          small
          :color="this.$vuetify.theme.themes.light.info"
          @click.stop="passwordRecoveryDialog = true"
          >Esqueci minha senha</v-btn
        >
      </div>
    </v-card>

    <v-dialog v-model="passwordRecoveryDialog" width="500">
      <v-card>
        <v-card-title class="primary white--text">
          Recuperar senha
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="recoveryEmail"
            label="E-mail casastrado:"
            placeholder="Informe o seu e-mail de cadastro"
            hint="Instruções de como recuperar sua senha serão enviadas no seu e-mail de cadastro"
          ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click.stop="passwordRecoveryDialog = false"> Cancelar </v-btn>
          <v-btn color="primary" @click="recoveryPassword()"> Enviar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="registerDialog" width="500">
      <Register @close-dialog="registerDialog = false" />
    </v-dialog>

    <v-dialog v-model="successRecoveryPasswordDialog" max-width="400">
      <v-card>
        <v-card-title> Sucesso! </v-card-title>
        <v-card-text> Enviamos instruções para o seu e-mail. </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.stop="successRecoveryPasswordDialog = false"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import bannerImage from "../assets/banner_logo.jpg";
import errorHandler from "@/helpers/error_handler";
import Register from "@/views/Users/Register";

export default {
  components: { Register },
  computed: {
    ...mapState("user", ["userData"]),
  },
  data: () => ({
    passwordRecoveryDialog: false,
    successRecoveryPasswordDialog: false,
    registerDialog: false,
    loading: false,
    srcBannerImage: bannerImage,
    recoveryEmail: null,
    errorMsg: null,
    form: {},
  }),
  methods: {
    ...mapActions(["user/signin"]),
    validateForm() {
      const errors = [];

      if (!this.form.email) errors.push("E-mail de acesso não informado");

      if (!this.form.password) errors.push("Senha de acesso não informada");

      return errors;
    },
    async signin() {
      try {
        const errors = this.validateForm();
        if (errors.length > 0) return this.$root.$errorDialog(errors);

        this.loading = true;

        await this["user/signin"](this.form);

        if (this.userData.Group.group_code === 4)
          this.$router.push("/admin/instituicoes");
        else this.$router.push("/feed");
      } catch (error) {
        const errorHandled = errorHandler.treatError(error);
        this.errorMsg = errorHandled[0];
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async recoveryPassword() {
      try {
        this.loading = true;

        await this.$axios.post("/usuarios/recuperarsenha", {
          email: this.recoveryEmail,
        });

        this.passwordRecoveryDialog = false;
        this.recoveryEmail = null;
        this.successRecoveryPasswordDialog = true;
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      if (Array.isArray(errorHandled))
        errorHandled.map((e) => this.$toast.error(e));
      else this.$toast.error(errorHandled);
    },
  },
};
</script>

<style scoped>
.wrapper {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.background {
  background-image: url("../assets/banner_logo_login.jpg");
  background-size: cover;
  height: 50vh;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
</style>
